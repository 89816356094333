import "./bootstrap";
import "../css/app.css";

import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
import { CaptureConsole } from "@sentry/integrations";

import { createApp, h } from "vue";
import { createInertiaApp } from "@inertiajs/vue3";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { ZiggyVue } from "../../vendor/tightenco/ziggy/dist/vue.m";

const appName = import.meta.env.VITE_APP_NAME || "RoadbookRally";

createInertiaApp({
    progress: {
        color: "#4B5563",
    },
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob("./Pages/**/*.vue")),
    setup({ el, App, props, plugin }) {
        const createdApp = createApp({ render: () => h(App, props) });

        if (import.meta.env.VITE_APP_ENV === "production") {
            const integrations = [
                Sentry.replayIntegration({
                    maskAllText: false,
                    blockAllMedia: false,
                }),
                // The following is all you need to enable canvas recording with Replay
                Sentry.replayCanvasIntegration(),

                new BrowserTracing(),
                new CaptureConsole({
                    levels: ["error"],
                }),
            ];

            const excludedFeedbackButtonRoutes = [
                "event.public.start-list-clock",
                "event.public.player",
                "track.public.player",
                "event.public.live",
            ];

            // console.log("route().current()", route().current());
            if (!excludedFeedbackButtonRoutes.includes(route().current())) {
                // console.log("integrations.push");
                integrations.push(
                    Sentry.feedbackIntegration({
                        // Additional SDK configuration goes in here, for example:
                        // colorScheme: "system",
                        buttonLabel: "Feedback",
                        triggerLabel: "Feedback",
                        showName: false,
                        formTitle: "Report a bug or share your feedback",
                        submitButtonLabel: "Send",
                    }),
                );
            }

            Sentry.init({
                createdApp,
                dsn: import.meta.env.VITE_SENTRY_LARAVEL_DSN,
                integrations: integrations,

                // Set tracesSampleRate to 1.0 to capture 100%
                // of transactions for performance monitoring.
                // We recommend adjusting this value in production
                tracesSampleRate: 0.5,

                // Session Replay
                replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
                replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

                ignoreErrors: [
                    // Random plugins/extensions
                    "top.GLOBALS",
                    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
                    "originalCreateNotification",
                    "canvas.contentDocument",
                    "MyApp_RemoveAllHighlights",
                    "http://tt.epicplay.com",
                    "Can't find variable: ZiteReader",
                    "jigsaw is not defined",
                    "ComboSearch is not defined",
                    "http://loading.retry.widdit.com/",
                    "atomicFindClose",
                    // Facebook borked
                    "fb_xd_fragment",
                    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
                    // reduce this. (thanks @acdha)
                    // See http://stackoverflow.com/questions/4113268
                    "bmi_SafeAddOnload",
                    "EBCallBackMessageReceived",
                    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
                    "conduitPage",
                ],
                denyUrls: [
                    // RBR
                    /posthog\.com/i,
                    /eu.i.posthog\.com/i,
                    /mapbox\.com/i,
                    /api.mapbox\.com/i,
                    /events.mapbox\.com/i,
                    /tawk\.to/i,
                    /va.tawk\.to/i,
                    /embed.tawk\.to/i,
                    // Facebook flakiness
                    /graph\.facebook\.com/i,
                    // Facebook blocked
                    /connect\.facebook\.net\/en_US\/all\.js/i,
                    // Woopra flakiness
                    /eatdifferent\.com\.woopra-ns\.com/i,
                    /static\.woopra\.com\/js\/woopra\.js/i,
                    // Chrome extensions
                    /extensions\//i,
                    /^chrome:\/\//i,
                    /^chrome-extension:\/\//i,
                    // Other plugins
                    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
                    /webappstoolbarba\.texthelp\.com\//i,
                    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
                ],
            });

            if (props.initialPage.props.user) {
                // console.log("props.initialPage.props.user", props.initialPage.props.user);
                Sentry.setUser({
                    // fullName: props.initialPage.props.user.name,
                    // name: props.initialPage.props.user.name,
                    email: props.initialPage.props.user.email,
                    // id: props.initialPage.props.user.id,
                });
            }
        }

        return createdApp.use(plugin).use(ZiggyVue).mount(el);
    },
});
